<script lang="ts">
export default {
    props: {
        data: {type: Object, required: true},
        validation: {type: Object, required: false, default: null},
        validationRules: {type: Object, required: false, default: null},
        dataKey: {type: String, required: true},
        fullDataKey: {type: Boolean, required: false},
        showKey: {type: String, required: false, default: null},
        isClearable: {type: Boolean, required: false},
        translationName: {type: String, required: true},
        isChips: {type: Boolean, required: false},
        withTooltip: {type: Boolean, required: false},
        textInfoCard: {type: String, required: false, default: null},
        textTooltip: {type: String, required: false, default: null},
        noLabel: {type: Boolean, required: false},
        isMultiple: {type: Boolean, required: false},
        suffix: {type: String, required: false, default: null},
        placeholder: {type: String, required: false, default: null},
        type: {type: String, required: false, default: null},
        disabled: {type: Boolean, required: false},
        validationHasIndex: {type: Number, required: false, default: null},
        validationKey: {type: String, required: false, default: null},
        validationKeyEach: {type: String, required: false, default: null},
        appendInnerIcon: {type: String, required: false, default: null},
        customId: {type: String, required: false, default: null},
    },
    computed: {
        rule() {
            if (this.validationRules) {
                if (this.validationKey) {
                    return this.validationRules[this.validationKey]
                } else {
                    if (this.showKey) {
                        return this.validationRules[`${this.dataKey}.${this.showKey}`]
                    }
                    return this.validationRules[this.dataKey]
                }
            } else {
                return this.validation ? this.validation : null
            }
            // return this.validationRules ? this.validationKey ? this.validationRules[this.validationKey] : this.showKey && this.validationRules[this.dataKey] ? this.validationRules[`${this.dataKey}.${this.showKey}`] : this.validationRules[this.dataKey] : this.validation ? this.validation : null
        },
        value: {
            get() {
                if (this.dataKey) {
                    let nestedValue = this.data
                    const keys = this.fullDataKey ? [this.dataKey] : this.dataKey.split('.') // FullDataKey is to avoid name with . to be splitted (example : editRow CT & RO datas needs to be full)

                    keys.forEach(key => {
                        if (this.isMultiple && Array.isArray(nestedValue)) {
                            nestedValue = nestedValue.map(val => val[key])
                        } else {
                            nestedValue = nestedValue && Object.prototype.hasOwnProperty.call(nestedValue, key) ? nestedValue[key] : undefined
                        }
                    })
                    return nestedValue
                }
                return null
            },
            set() { // newValue
                // ENABLE V-MODEL ON INPUTS, number had a refresh issue with just value prop.
                // On pourrait v-model tous les inputs et emit updatedata ici.
            }
        },
    },
    methods: {
        triggerValidation () {
            this.validation?.$touch()
        },
        getErrors() {
            // SI VALIDATION LOOP & ON A L'INDEX comme par exemple contact.*.phone : required, contact.*.name : required ...
            if (this.validation && typeof this.validationHasIndex === 'number' && this.validationHasIndex > -1) {
                if (this.validation?.$each?.$response?.$errors[this.validationHasIndex]) {
                    return this.validation.$each.$response.$errors[this.validationHasIndex][this.validationKeyEach || this.dataKey].map(e => e.$message)
                }
            } else if (this.validation && this.validation?.$each) { // SI VALIDATION LOOP & ON A PAS L'INDEX (pour les validations autocomplete multiple qui n'ont pas de multichamps)
                let errors = []
                if (this.validation?.$each?.$response?.$errors) {
                    this.validation.$each.$response.$errors.forEach( error => {
                        if (error[this.validationKeyEach || this.dataKey]) {
                            const messages = error[this.validationKeyEach || this.dataKey].map(e => e.$message)
                            errors = [...errors, ...messages]
                        }
                    })
                }
                return errors

            } else if (this.validation) {
                return this.validation.$errors.map(e => e.$message)
            }
            return null
        },
        getMin () {
          return this.rule && Array.isArray(this.rule) ? this.rule.find( r => r.includes('min:'))?.replace('min:', '') : null
        },
        getMax () {
          return this.rule && Array.isArray(this.rule) ? this.rule.find( r => r.includes('max:'))?.replace('max:', '') : null
        },
    }
}
</script>
