<template>
  <FormLabel v-if="data && (value !== undefined || rule)">
    <v-autocomplete
      v-if="rule"
      :id="customId || dataKey"
      v-model="setValue"
      :multiple="isMultiple"
      :items="autocompleteUrlChoices || []"
      :clearable="isClearable"
      item-title="v"
      item-value="v"
      variant="outlined"
      density="compact"
      autocomplete="off"
      :hide-no-data="true"
      :loading="loading"
      :disabled="disabled"
      :chips="isMultiple && Array.isArray(setValue)"
      :closable-chips="isMultiple && Array.isArray(setValue)"
      no-data-text="Aucun choix disponible"
      :error-messages="getErrors()"

      @update:model-value="updateData($event); validation?.$touch()"
      @input="event => {getItemsDebounce(event); validation?.$touch()}"
      @click:clear="event => getItems(event)"
      @blur="validation && validation.$touch()"
    >
      <template #selection="{ item }">
        <v-chip
          :variant="$config.public.chips_variant || 'tonal'"
          v-if="isChips && !loading"
          size="small"
        >
          {{ item.value }}
        </v-chip>
        <span v-else-if="!loading">{{ item.value }}</span>
        <span v-else>Chargement en cours...</span>
      </template>
      <template #append-item>
        <div v-intersect="endIntersect" />
      </template>
    </v-autocomplete>

    <div v-else-if="showValue">
      <div v-if="withTooltip">
        <v-tooltip :text="$translate(`${translationName}.${dataKey}.tooltip.${value}`)">
          <template #activator="{ props }">
            <v-chip
              v-if="isChips"
              :variant="$config.public.chips_variant || 'tonal'"
              v-bind="props"
              :color="$getColor(showValue)"
            >
              {{ showValue }}
            </v-chip>
            <span
              v-else
              v-bind="props"
            > {{ showValue }}</span>
          </template>
        </v-tooltip>
      </div>
      <div v-else>
        <template v-if="Array.isArray(showValue)">
          <span
            v-for="(val, i) in showValue"
            :key="val"
          >
            <v-chip
              :variant="$config.public.chips_variant || 'tonal'"
              v-if="isChips"
              :color="$getColor(`${translationName}.${dataKey}.${value}`)"
            >
              {{ val }}
            </v-chip>
            <span v-else>{{ val }}{{ i !== showValue.length - 1 ? ', ' : '' }} </span>
          </span>
        </template>
        <template v-else>
          <v-chip
            v-if="isChips"
            :variant="$config.public.chips_variant || 'tonal'"
            :color="$getColor(`${translationName}.${dataKey}.${value}`)"
          >
            {{ showValue }}
          </v-chip>
          <span v-else>{{ showValue }}</span>
        </template>
      </div>
    </div>
    <div v-else>
      <small class="font-italic text-disabled">-</small>
    </div>
  </FormLabel>
</template>

<script lang="ts">
import formFieldsInit from '../../utils/formFieldsInit.vue'
import FormLabel from "./label.vue";

export default {
    name: "AutocompleteField",
    components: {FormLabel},
    extends: formFieldsInit,
    props: {
        fetchKey: {type: String, required: false, default: null},
        toTranslate: {type: Boolean, required: false},
        editKey: {type: String, required: false, default: null},
    },
    emits: ['updateData'],
    data() {
        return {
            loading: false,
            autocompleteUrlChoices: [],
            autocompleteData: [],
            search: null,
            page: 1,
            last_page: null,
            debounceTimeout: null
        }
    },
    computed: {
        setValue: {
            get() {
                return this.showValue
            },
            set(newValue) {
              // WHY DO WE NEED TO UPDATE (with only names) HERE WHEN EVENT IS UPDATING ?
                this.$emit('updateData', newValue)
            }
        },
        showValue() {
            if (this.value) {
                if (Array.isArray(this.value)) {
                    const valueUpdated = this.value.map(val => {
                        return Object.prototype.hasOwnProperty.call(val, 'v') ? val.v : val.t
                    })
                    return valueUpdated.filter(val => val) // prevent to have null in array if autocomplete route does'nt have matched value
                }
                else if (Object.prototype.hasOwnProperty.call(this.value, 'v')) {
                    return this.value.v
                } else if (Object.prototype.hasOwnProperty.call(this.value, 't')) {
                    return this.value.t
                } else if (this.toTranslate) {
                    return this.$translate(`${this.translationName}.${this.dataKey}.${this.value}`)
                } else {
                    return this.value
                }
            }
            return null
        },
        requestPath() {
            if (this.rule) {
                let values = null
                if (Array.isArray(this.rule)) {
                    values = this.rule.find(r => r.includes('autocompleteUrl:'))
                } else if (typeof this.rule === 'object') {
                    const rules = Object.values(this.rule)
                    values = rules.find(r => r.includes('autocompleteUrl:'))
                }
                if (values) {
                    values = values.replace('autocompleteUrl:', '');
                    return values
                }
            }
            return null
        },
    },
    mounted() {
        this.getBasicData()
        this.getItems(null)
        // getBasicData will push real value (v needed)
        // if (this.data && this.value) {
        //     this.autocompleteData.push(this.value)
        // }
    },
    methods: {
        getBasicData() {
            if (this.value && this.rule) {
                let k = ""
                if (this.isMultiple && Array.isArray(this.value)) {
                    k = this.value.map(val => val.k).join(',')
                } else {
                    k = this.fetchKey ? this.fetchKey : this.value.k
                }

                k && this.$api.setRequest({
                    url: `${this.requestPath}`,
                    // query :  [{name : "k", value : k }],
                    query: {k: k},

                    method: "GET",
                }).then(res => {
                    this.setValue = res.data.length ? this.isMultiple ? res.data : res.data[0] : null
                    this.autocompleteData = [...this.autocompleteData, ...res.data]
                })
            }
        },
        updateData(value) {
            let formatValue = null
            if (value) {
                if (this.isMultiple) {
                    if (Array.isArray(value)) {
                        formatValue = []
                        value.forEach(val => {
                            const objectData = this.autocompleteData.find(a => val.includes(a.v)) // IF ALREADY SETTED CHOICE IS NOT IN PROPOSED DATA 
                            const objectChoice = this.autocompleteUrlChoices.find(a => val.includes(a.v)) // IF SELECTED CHOICE WAS NOT INITIALY IN AUTOCOMPLETEDATA
                            if (objectData || objectChoice) {
                              formatValue.push(objectData || objectChoice)
                            } else {
                              console.log('not found value', val)
                            }
                        })
                    } else { // POURQUOI UN ISMULTIPLE NE SERAIT PAS UN ARRAY ? PAS INITIALISÉ ?
                        formatValue =  this.autocompleteUrlChoices.filter(a => value.includes(a.v))
                    }
                } else {
                    formatValue = this.autocompleteUrlChoices.find(a => a.v == value)
                }
            }
            this.autocompleteData = this.isMultiple ? formatValue : [formatValue] // clumsy but needed
            this.$emit('updateData', formatValue)
        },
        getItemsDebounce(event) {
            window.clearTimeout(this.debounceTimeout);
            this.autocompleteUrlChoices = []
            const those=this;
            this.debounceTimeout= setTimeout(function(){
                those.getItems(event);
            }, 200)
        },
        async getItems(event) {
            if (event) { // reset infinite scroll on search change
                this.page = 1
                this.last_page = null
                this.autocompleteUrlChoices = []
                this.search = event.srcElement.value
            }

            const pagination = `&page[number]=${this.page}`
            if (this.requestPath) {
                this.loading = true
                await this.$api.setRequest({
                    url: `${this.requestPath}${this.search ? `&search=${this.search}` : ''}${pagination}`,
                    method: "GET",
                }).then((res) => {
                    if (this.page === 1) {
                        this.autocompleteUrlChoices = []
                    }
                    this.last_page = res.meta.last_page;
                    if (res.data.length) {
                        this.autocompleteUrlChoices = [...this.autocompleteUrlChoices, ...res.data]
                    } else {
                        this.autocompleteUrlChoices = this.autocompleteData.filter(data => data ? data.k === this.value : false)
                    }
                    this.loading = false
                })
            }
        },
        endIntersect(isIntersecting) {
            if (isIntersecting && !this.loading && this.page < this.last_page && this.autocompleteUrlChoices.length) {
                this.page += 1
                this.getItems()
            }
        },
    },
}
</script>